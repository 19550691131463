// AssistantBot.js

import React, { useRef } from 'react';
import { Typography, Box, TextField, Button, Modal, Fab, Grid, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { AudioConfig, ResultReason, SpeechConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import MicIcon from '@mui/icons-material/Mic';
import MicNoneIcon from '@mui/icons-material/MicNone';
import { fetchFromToChatBot, getTokenOrRefresh, postRequestToChatBot } from '../utils/common';
import useSingin from '../hooks/useSignin';
import { eraseCookie } from '../utils/cookie';

const ChatBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderColor: '#e2e2e2',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '8px',
    backgroundColor: '#fff',
    width: '90vw',
    [`@media (min-width: 600px)`]: {
        width: '400px',
        height: '500px'
    }
});


const UserMessage = styled(Typography)({
    alignSelf: 'flex-end',
    backgroundColor: '#e1f5fe',
    padding: '8px',
    borderRadius: '8px',
    marginBottom: '8px'
});

const AssistantMessage = styled(Typography)({
    alignSelf: 'flex-start',
    backgroundColor: '#f5f5f5',
    padding: '8px',
    borderRadius: '8px',
    marginBottom: '8px'
});

const AssistantBot = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [chatHistory, setChatHistory] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSpeechListening, setIsSpeechListening] = React.useState(false);
    const [summary, setSummary] = React.useState("");
    const { sessionId } = useSingin();
    const chatContainerRef = useRef(null);
    const inputRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
        if (isOpen && chatHistory.length === 0) {
            if (sessionId) {
                setIsLoading(true)
                fetchFromToChatBot(`mktchat/getChatHistory?sessionId=${sessionId ?? ""}&env=collections`)
                    .then(response => {
                        response.json().then(data => {
                            setSummary(data.summary)
                            setChatHistory(data.history ?? [])
                        })
                        setIsLoading(false)
                    }).catch(() => {
                        setIsLoading(false)
                    })
            } else {
                setChatHistory([{ role: 'Assistant', content: { text: "Hello, I'm your shopping assistant. Ask me anything." } }]);
            }
        }
        if (isOpen) {
            inputRef?.current?.focus();
        }
    }, [isOpen, chatHistory]);

    React.useEffect(() => {
        getToken()
    }, []);

    const getToken = async () => {
        await getTokenOrRefresh();
    }

    const handleSendMessage = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const history = [...chatHistory, { role: 'Customer', content: { text: message } }];
            setChatHistory(history);
            setMessage('');
            const messageHistory = history.map(item => `${item.role}: ${item.content.text}`).join(' ');
            const body = { Text: message, History: messageHistory, summary, env: 'collections' };
            if (sessionId) {
                body.sessionId = sessionId;
            }
            const response = await postRequestToChatBot('mktchat/ask', body);

            if (response?.status === 200) {
                const data = await response.json();
                setSummary(data.summary)
                setChatHistory([
                    ...history,
                    { role: 'Assistant', content: data }
                ]);
            } else {
                enqueueSnackbar(t('messageFailed'), {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }
            setIsLoading(false);
            inputRef?.current?.focus();
        } catch (error) {
            console.error("Error:", error);
            enqueueSnackbar(t('messageFailed'), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    };

    const renderMessage = (msg, index) => {
        if (msg.role === 'Assistant') {
            return (
                <>
                    <AssistantMessage key={`text-${index}`} sx={{ fontSize: '14px' }}>{msg.content.text}</AssistantMessage>
                    {
                        msg.content.productRecommendation && msg.content?.productRecommendation.length > 0 &&
                        <AssistantMessage key={index} sx={{ width: '100%' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                                {msg.content.productRecommendation.map((nft, i) => (
                                    <Grid xs={4} item key={i} >
                                        <Button
                                            type='button'
                                            target="_blank"
                                            href={`/marketplace/detail/${nft.id}`}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                fontSize: '12px',
                                                borderRadius: '0',
                                                margin: '0 10px',
                                                height: '100%',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                justifyContent: 'start'
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                alt="Product"
                                                src={nft.imageUrl}
                                                sx={{
                                                    width: '80px',
                                                    height: '80px',
                                                    objectFit: 'cover',
                                                    margin: '0 auto',
                                                    display: 'block',
                                                }}
                                            />
                                            {nft.name}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </AssistantMessage >
                    }
                </>
            );
        }
        return <UserMessage key={index} sx={{ fontSize: '14px' }}>{msg.content.text}</UserMessage>;
    };

    const sttFromMic = async () => {
        const tokenObj = await getTokenOrRefresh();
        const speechConfig = SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = i18n.language === "zh" ? "zh-CN" : 'en-US';

        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
        setIsSpeechListening(true)
        try {
            recognizer.recognizeOnceAsync(result => {
                if (result.reason === ResultReason.RecognizedSpeech) {
                    setMessage(result.text)
                } else if (result.reason === ResultReason.Canceled) {
                    eraseCookie('speech-token');
                    enqueueSnackbar(t('pleaseTryAgain'), {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                setIsSpeechListening(false);
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Fab
                color="primary"
                style={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => setIsOpen(true)}
            >
                <ChatIcon />
            </Fab>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                sx={{ outline: "none", border: 'none' }}
                aria-labelledby="chat-modal-title"
            >
                <ChatBox
                    sx={{
                        position: 'absolute',
                        bottom: 20,
                        right: 20,
                        width: '400px',  // Increased width
                        height: '500px', // Introduced height
                    }}
                >
                    <Typography id="chat-modal-title" variant="h6">Chat with Assistant</Typography>

                    <Box mt={2} flex={1} display="flex" flexDirection="column" maxHeight={380} overflow="auto" ref={chatContainerRef}>
                        {chatHistory.map((msg, index) => renderMessage(msg, index))}
                        {/* {isLoading && <AssistantMessage>Writing...</AssistantMessage>} */}
                    </Box>

                    <form onSubmit={handleSendMessage}>
                        <Box mt={2} display="flex" alignItems="center">
                            <TextField
                                inputRef={inputRef}
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={message}
                                disabled={isLoading}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type a message..."
                                sx={{ marginRight: 1 }}
                            />
                            <IconButton disabled={isSpeechListening} aria-label="mic" onClick={() => sttFromMic()}>
                                {isSpeechListening ? <MicIcon sx={{ fill: '#50C878' }} /> : <MicNoneIcon />}
                            </IconButton>
                            <LoadingButton variant="contained" type="submit" color="primary" loading={isLoading}>
                                Send
                            </LoadingButton>
                        </Box>
                    </form>
                </ChatBox>
            </Modal >
        </>
    );
};

export default AssistantBot;
