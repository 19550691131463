import { useSnackbar } from 'notistack';
import { Button, Box, Typography } from '@mui/material';
import sharpContentCopy from '@iconify/icons-ic/sharp-content-copy';
import { Icon } from '@iconify/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { reduceHexAddress } from '../utils/common';
// ----------------------------------------------------------------------

export default function AddressCopyButton(props) {
  const { address, type = '' } = props
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onCopy = () => {
    enqueueSnackbar(t("copiedToClipBoard"), { variant: 'success' });
  };

  const startIcon = {}
  switch (type) {
    case "contract":
      startIcon.startIcon = <Icon icon="teenyicons:contract-outline" width="18px" />
      break;
    case "diamond":
      startIcon.startIcon = <Icon icon="mdi-light:diamond" width="18px" />
      break;
    default:
      break;
  }

  return (
    <CopyToClipboard text={address} onCopy={onCopy}>
      <Button
        variant="outlined"
        endIcon={
          <Icon icon={sharpContentCopy} width="17px" />
        }
        {...startIcon}
        color='inherit'
        onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
      >
        {reduceHexAddress(address)}
      </Button>
    </CopyToClipboard>
  )
}