// material
import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextFieldStyle } from '../CustomInput';
import StyledButton from './StyledButton';
import { postRequest } from '../../utils/common';
import useSingin from '../../hooks/useSignin';

export default function SignInComponent({ setOpenAccountPopup, isDialog, title, onSuccess }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { setSessionId, getUser, setOpenSignUpDlg, setOpenForgotPasswordDlg, setOpenSignInDlg } = useSingin();
    const [searchParams, setSearchParams] = useSearchParams();
    const [emailFromURL, setEmailFromURL] = useState("");

    const loginEmailRef = useRef();
    const loginPasswordRef = useRef();

    React.useEffect(() => {
        const emailFromQuery = searchParams.get("email");
        if (emailFromQuery) {
            setEmailFromURL(decodeURIComponent(emailFromQuery));
        }
    }, [])

    const openSignUpDlg = () => {
        setOpenSignUpDlg(true);
    };
    const openForgotPasswordDlg = () => {
        setOpenForgotPasswordDlg(true);
    };

    const closeSignUpDlg = () => {
        setOpenSignUpDlg(false);
    };
    const closeForgotPasswordDlg = () => {
        setOpenForgotPasswordDlg(false);
    };

    const closeSignInDlg = () => {
        setOpenSignInDlg(false);
    };

    const handleClickOpenSignUpDlg = () => {
        closeForgotPasswordDlg();
        closeSignInDlg();
        openSignUpDlg();
    };

    const handleClickForgotPasswordDlg = () => {
        closeSignInDlg();
        closeSignUpDlg();
        openForgotPasswordDlg();
    }

    const signInRequest = () => {
        const email = loginEmailRef.current.value;
        const password = loginPasswordRef.current.value;
        postRequest("api/v2/authentication/login", { email, password }, null, false).then(async (res) => {
            const data = await res.json();
            if (res.status === 200) {
                localStorage.setItem("sessionId", data.sessionId);
                setSessionId(data.sessionId);
                getUser();
                enqueueSnackbar(t(data.message), { variant: 'success' });
                if (setOpenAccountPopup) setOpenAccountPopup(false);
                if (onSuccess) {
                    onSuccess();
                } else {
                    navigate('/');
                }
            } else {
                enqueueSnackbar(t(data.message), { variant: 'error' });
            }
        });
    }

    return (
        <Box component="div" sx={{ maxWidth: 350, m: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {!isDialog &&
                <Typography variant="h3" component="div" sx={{ color: 'text.primary' }} align="center">
                    {t(title)}
                </Typography>
            }
            <form>
                <Grid container spacing={2} sx={{ my: 4 }}>
                    <Grid item xs={12} md={12}>
                        <TextFieldStyle autoComplete='email' defaultValue={emailFromURL || ""} fullWidth inputRef={loginEmailRef} className='text-field' label={t("E-Mail")} size="small" type="email" />
                    </Grid>
                    <Grid item xs={12} md={12} >
                        <TextFieldStyle autoComplete='password' fullWidth inputRef={loginPasswordRef} className='text-field' label={t("Password")} size="small" type='password' />
                    </Grid>
                    <Grid item xs={12} sx={{ pt: '8px !important', mt: '10px' }}>
                        <StyledButton
                            variant="contained"
                            fullWidth
                            onClick={async () => {
                                signInRequest();
                            }}
                        >
                            {t('signIn')}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: '15px !important' }}>
                        <StyledButton variant="outlined" fullWidth onClick={handleClickOpenSignUpDlg}>
                            {t('signUp')}
                        </StyledButton>
                    </Grid>
                </Grid>
            </form>
            <Grid className='forgot-password' item xs={12} sx={{ pt: '8px !important' }}>
                <Link onClick={handleClickForgotPasswordDlg}>{t('forgotYourPassword')}</Link>
            </Grid>
        </Box >
    );
}