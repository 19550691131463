import { Icon } from '@iconify/react';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  // {
  //   title: 'Explorer',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.explorer,
  //   disable: false
  // },
  {
    title: 'marketplace',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.marketplace,
    disable: false
  },
  {
    title: 'collections',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.collection,
    disable: false
  },
  {
    title: 'list',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.create,
    disable: false
  },
  // {
  //   title: 'Activity',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.activity,
  //   disable: false
  // },
  // {
  //   title: 'Features',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.features,
  //   disable: false
  // },
  // {
  //   title: 'Rewards',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.rewards,
  //   disable: false
  // },
  // {
  //   title: 'Docs',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_DOCS,
  //   disable: true
  // }
];

export default menuConfig;
